<template>
  <div>
    <portal to="tabs">
      <v-tabs v-model="tabSelected" background-color="transparent">
        <v-tab><v-icon class="pr-2">mdi-phone-log</v-icon>Журнал звонков</v-tab>
        <v-tab><v-icon class="pr-2">mdi-calendar-check</v-icon>Задачи</v-tab>
      </v-tabs>
    </portal>
    <call-groups v-if="tabSelected===0"
      :filter.sync="filter"
      :callTargets="callTargets"
      :callResults="callResults"
      :callDirections="callDirections"
      :statuses="statuses"
    />
    <call-tasks v-if="tabSelected===1"
      :filter.sync="filter"
      :callTargets="callTargets"
      :callResults="callResults"
      :callDirections="callDirections"
      :statuses="statuses"
    />
  </div>
</template>

<script>
import moment from "moment";
import {Entity_CallResult, Entity_CallTarget} from "../../../EntityStoreCacheService";
import CallGroups from "@/views/Call/CallGroups.vue";
import CallTasks from "@/views/Call/CallTasks.vue";

export default {
  components: {CallTasks, CallGroups},
  data:()=>({
    tabSelected:null,
    filter: {
      dateFrom:moment().format('DD.MM.YYYY'),
      dateTo:moment().format('DD.MM.YYYY'),
      callDirections: [],
      statuses: [],
      phone: null,
      callTarget: null,
      callResult: null,
      userClientState: [],
      users: []
    },
    callTargets:[],
    callResults:[],
    callDirections:[
      {name:'Внутренний',id:0,icon:'mdi-phone-incoming-outgoing'},
      {name:'Входящий',id:1, icon: 'mdi-phone-incoming'},
      {name:'Исходящий',id:2, icon: 'mdi-phone-outgoing'},
    ],
    statuses: [
      {name:'В процессе',id:'progress', color:'#428eda'},
      {name:'Успешно',id:'success', color:'#02924d'},
      {name:'Не отвечено',id:'fail', color:'#F44336'},
      {name:'Не отвечено в нерабочее время',id:'failNonWork', color:'#607D8B'},
    ],
    //playbackRate:1
  }),
  mounted() {
    new Entity_CallTarget()
        .selects(Entity_CallTarget.id)
        .selects(Entity_CallTarget.name)
        .selects(Entity_CallTarget.comment)
        .selects([Entity_CallTarget.callResults, Entity_CallResult.id].join('.'))
        .selects([Entity_CallTarget.callResults, Entity_CallResult.name].join('.'))
        .selects([Entity_CallTarget.callResults, Entity_CallResult.type].join('.'))
        .selects([Entity_CallTarget.callResults, Entity_CallResult.comment].join('.'))
        .order(Entity_CallTarget.sort, true)
        .page(1)
        .onPage(1000)
        .addStateCallback((v)=>{
          if(v.success) this.callTargets = v.items;
        })
        .request()
    new Entity_CallResult()
        .selects(Entity_CallResult.id)
        .selects(Entity_CallResult.name)
        .selects(Entity_CallResult.type)
        .selects(Entity_CallResult.comment)
        .selects([Entity_CallResult.callTargets, Entity_CallTarget.id].join('.'))
        .order(Entity_CallResult.type, true)
        .order(Entity_CallResult.sort, true)
        .page(1)
        .onPage(1000)
        .addStateCallback((v)=>{
          if(v.success) this.callResults = v.items;
        })
        .request()
  },
}
</script>