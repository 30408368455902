<template>
  <layout-view navTitle="Фильтры">
    <template v-slot:nav>
      <div class="px-4">
        <date-interval name="Интервал" :valueFrom.sync="localFilter.dateFrom" :valueTo.sync="localFilter.dateTo" valueFromMinus="month:1" />

        <autocomplete-multiple name="Тип звонка" :items="callDirections" :select.sync="localFilter.callDirections" textOverflow>
          <template v-slot:itemIcon="{item}">
            <v-icon small class="mr-1" style="margin-top:-3px">{{item.icon}}</v-icon>
          </template>
          <template v-slot:selectedInfo="{item}">
            <v-icon small class="mr-1" style="margin-top:-2px">{{item.icon}}</v-icon> {{item.name}}
          </template>
        </autocomplete-multiple>
        <text-field name="Номер телефона" :value.sync="localFilter.phone"/>
        <checkboxes name="Тип клиента" :items="[{id:'first',name:'Анон. первичный'},{id:'second',name:'Анон. вторичный'},{id:null,name:'Из базы'}]" :selects.sync="localFilter.userClientState"/>
        <user-autocomplete-multiple name="Пользователь" :select.sync="localFilter.users"/>
      </div>
    </template>
    <template v-slot:content="{height}">
      <v-sheet class="overflow-hidden" rounded>
        <v-toolbar class="bar" dense elevation="0" color="transparent" max-height="48px">
          <v-toolbar-title class="body-2 font-weight-medium">Список задач</v-toolbar-title>
          <v-spacer/>
        </v-toolbar>
        <v-data-table
            :headers="[
              { text: '', value: 'data-table-expand', sortable:false},
              { text: 'ID', value: 'id', class:'text-no-wrap', sortable:false},
              { text: 'Дата и время', value: 'createTime', class:'text-no-wrap', sortable:false },
              { text: 'Тип звонка', value: 'callDirection', class:'text-no-wrap', sortable:false},
              { text: 'Статус', value: 'status', class:'text-no-wrap', sortable:false},
              { text: 'Клиент', value: 'userClient', class:'text-no-wrap', sortable:false},
              { text: 'Кто', value: 'fromPhoneNumber', class:'text-no-wrap', sortable:false},
              { text: 'Кому', value: 'toPhoneNumber', class:'text-no-wrap', sortable:false},
              { text: 'Цель', value: 'callTarget', class:'text-no-wrap', sortable:false},
              { text: 'Результат', value: 'callResult',  class:'text-no-wrap', sortable:false},
              { text: 'Длит.: меню / дозвон / разговор', class:'text-no-wrap', sortable:false},
            ]"
            :items="items"
            :single-expand="true"
            :expanded.sync="expanded"
            show-expand
            hide-default-footer
            fixed-header
            :loading="state.isLoading"
            :height="height-48"
            :items-per-page="10000"
            dense
            class="table10"
        >

          <template v-slot:item="{ item, expand, isExpanded}">
            <tr @click="callGroup=item" :class="isExpanded ? 'grey lighten-4' :''">
              <td><v-icon v-if="item.callGroups.length>0" @click.stop="expand(!isExpanded)" >{{ isExpanded ? 'mdi-chevron-down' : 'mdi-chevron-right' }}</v-icon></td>
              <td>{{item.id}}</td>
              <td>{{$tools.dateTimeFormat(item.createTime,'DD.MM.YYYY HH:mm')}}</td>
              <td><call-direction :call-group="item" widget/></td>
              <td>
                <call-group-state :call-group="item" class="d-inline-block"/>
                <tooltip v-if="!$tools.isEmpty(item.comment)" bottom>
                  <template v-slot:default><v-icon small class="ml-2">mdi-comment-text-outline</v-icon></template>
                  <template v-slot:content>
                    {{item.comment}}
                  </template>
                </tooltip>
              </td>
              <td>
                <div v-if="item.userClient===null">
                  <div v-if="item.userClientState==='first'">Анон.первичный</div>
                  <div v-else-if="item.userClientState==='second'">Анон.вторичный</div>
                </div>
                <div v-else>
                  {{$tools.userFullName(item.userClient)}}
                  <v-btn @click.stop x-small icon depressed :href="'/panel/user/'+item.userClient.type+'/'+item.userClient.id+'/'" target="_blank">
                    <v-icon small>mdi-open-in-new</v-icon>
                  </v-btn>
                </div>
              </td>
              <td>
                <call-phone :phone="item.fromPhoneNumber" :title="item.fromPhoneNumber"/>
              </td>
              <td>
                <call-phone :phone="item.toPhoneNumber" :title="item.toPhoneNumber"/>
              </td>
              <td>
                <div v-if="item.callTarget!==null">{{item.callTarget.name}}</div>
              </td>
              <td>
                <div v-if="item.callResult!==null">
                  <v-avatar v-if="item.callResult.type==='success'" color="success" size="16" style="margin-top:-2px"><v-icon color="white" size="12">mdi-check</v-icon></v-avatar>
                  <v-avatar v-else-if="item.callResult.type==='info'" color="blue" size="16" style="margin-top:-2px"><v-icon color="white" size="20">mdi-information-symbol</v-icon></v-avatar>
                  <v-avatar v-else color="red" size="16" style="margin-top:-2px"><v-icon color="white" size="12">mdi-close</v-icon></v-avatar>
                  {{item.callResult.name}}
                </div>
              </td>
              <td>
                {{$tools.isEmpty(item.forwardDuration) ? '-' : $tools.secondsToTime(item.forwardDuration)}} /
                {{$tools.isEmpty(item.talkWaitDuration) ? '-' : $tools.secondsToTime(item.talkWaitDuration)}} /
                {{$tools.isEmpty(item.talkDuration) ? '-' : $tools.secondsToTime(item.talkDuration)}}
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{headers, item}">
            <tr @click="callGroup=item1" v-for="(item1, k) in item.callGroups" :key="k" class="grey lighten-4">
              <td></td>
              <td>{{item1.id}}</td>
              <td>{{$tools.dateTimeFormat(item1.createTime,'DD.MM.YYYY HH:mm')}}</td>
              <td><call-direction :call-group="item1" widget/></td>
              <td>
                <call-group-state :call-group="item1" class="d-inline-block"/>
                <tooltip v-if="!$tools.isEmpty(item1.comment)" bottom>
                  <template v-slot:default><v-icon small class="ml-2">mdi-comment-text-outline</v-icon></template>
                  <template v-slot:content>
                    {{item1.comment}}
                  </template>
                </tooltip>
              </td>
              <td>
                <div v-if="item1.userClient===null">
                  <div v-if="item1.userClientState==='first'">Анон.первичный</div>
                  <div v-else-if="item1.userClientState==='second'">Анон.вторичный</div>
                </div>
                <div v-else>
                  {{$tools.userFullName(item1.userClient)}}
                  <v-btn @click.stop x-small icon depressed :href="'/panel/user/'+item1.userClient.type+'/'+item1.userClient.id+'/'" target="_blank">
                    <v-icon small>mdi-open-in-new</v-icon>
                  </v-btn>
                </div>
              </td>
              <td>
                <call-phone :phone="item1.fromPhoneNumber" :title="item1.fromPhoneNumber"/>
              </td>
              <td>
                <call-phone :phone="item1.toPhoneNumber" :title="item1.toPhoneNumber"/>
              </td>
              <td>
                <div v-if="item1.callTarget!==null">{{item1.callTarget.name}}</div>
              </td>
              <td>
                <div v-if="item1.callResult!==null">
                  <v-avatar v-if="item1.callResult.type==='success'" color="success" size="16" style="margin-top:-2px"><v-icon color="white" size="12">mdi-check</v-icon></v-avatar>
                  <v-avatar v-else-if="item1.callResult.type==='info'" color="blue" size="16" style="margin-top:-2px"><v-icon color="white" size="20">mdi-information-symbol</v-icon></v-avatar>
                  <v-avatar v-else color="red" size="16" style="margin-top:-2px"><v-icon color="white" size="12">mdi-close</v-icon></v-avatar>
                  {{item1.callResult.name}}
                </div>
              </td>
              <td>
                {{$tools.isEmpty(item1.forwardDuration) ? '-' : $tools.secondsToTime(item1.forwardDuration)}} /
                {{$tools.isEmpty(item1.talkWaitDuration) ? '-' : $tools.secondsToTime(item1.talkWaitDuration)}} /
                {{$tools.isEmpty(item1.talkDuration) ? '-' : $tools.secondsToTime(item1.talkDuration)}}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-sheet>
      <call-group-detail :callGroup.sync="callGroup" :call-targets="callTargets" @change="load()" />
    </template>
  </layout-view>
</template>

<script>
import LayoutView from "@/components/LayoutView.vue";
import DateInterval from "@/componentsV2/base/DateInterval.vue";
import AutocompleteMultiple from "@/componentsV2/base/AutocompleteMultiple.vue";
import UserAutocompleteMultiple from "@/componentsV2/custom/UserAutocompleteMultiple.vue";
import TextField from "@/componentsV2/base/TextField.vue";

import Checkboxes from "@/componentsV2/base/Checkboxes.vue";
import CallGroupDetail from "@/views/Call/CallGroupDetail.vue";
import axios from "axios";
import State from "@/plugins/state";
import CallDirection from "@/views/Call/CallDirection.vue";
import CallGroupState from "@/views/Call/CallGroupState.vue";
import Tooltip from "@/componentsV2/base/Tooltip.vue";
import CallPhone from "@/components/CallPhone.vue";
import moment from "moment/moment";

export default {
  components: {
    CallPhone,
    Tooltip, CallGroupState,
    CallDirection,
    CallGroupDetail,
    Checkboxes,
    LayoutView,
    DateInterval,
    AutocompleteMultiple,
    UserAutocompleteMultiple,
    TextField,
  },
  props: ['filter','callTargets','callDirections'],
  data:()=>({
    callGroup: null,
    localFilter:null,
    timerId: null,

    state:new State(),
    items:[],
    expanded:[],
  }),
  watch: {
    filter: {
      handler() {
        this.localFilter = this.filter;
        this.$tools.throttle(()=>{
          this.load();
        }, 500, 'callGroups')
      },
      deep: true,
    },
    localFilter: {
      handler() {
        this.$emit('update:filter', this.localFilter);
        this.$tools.throttle(()=>{
          this.load();
        }, 500, 'callGroups')
      },
      deep: true,
    },
  },
  beforeMount(){
    this.localFilter = this.filter
  },
  mounted() {
    this.timerId = setInterval(() => {
      this.load();
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.timerId)
  },
  methods:{
    load(){
      if(this.state.isLoading) return;
      let arr = [];
      arr.push('dateFrom='+moment(this.localFilter.dateFrom, 'DD-MM-YYYY').format('YYYY-MM-DD 00:00:00'));
      arr.push('dateTo='+moment(this.localFilter.dateTo, 'DD-MM-YYYY').format('YYYY-MM-DD 23:59:59'));
      this.localFilter.callDirections.forEach(v=>{
        arr.push('callDirection[]='+v.id);
      })
      if(!this.$tools.isEmpty(this.localFilter.phone))
        arr.push('phone='+this.localFilter.phone);
      this.localFilter.userClientState.forEach(v=>{
        arr.push('userClientState[]='+v.id);
      })
      this.localFilter.users.forEach(v=>{
        arr.push('user[]='+v.id);
      })

      this.state.stateLoading()
      axios.get('/api/callGroup/tasks/?'+arr.join('&')).then(r=>{
        this.items = r.data
        this.state.stateSuccess()
      }).catch(() =>{
        this.state.stateError()
      });
    }
  }
}
</script>