<template>
  <v-autocomplete
      v-model="localSelect"
      :items="items"
      :label="name"
      :search-input.sync="localSearchText"
      :background-color="typeof backgroundColor!=='undefined' ? backgroundColor : null"
      item-value="id"
     :item-text="getItemText"
      return-object
      outlined
      dense
      hide-details
      multiple
      class="autocomplete"
      style="margin-bottom: 16px"
      :error-messages="typeof required!=='undefined' && localSelect.length===0 ? 'Поле обязательно' : null"
      @click="items.length===0 ? $emit('load') : null"
      :disabled="readonly"
  >
    <template v-slot:append>
      <v-sheet v-if="localLoading" width="24" height="24" class="d-flex align-center justify-center">
        <v-progress-circular color="primary" size="16" width="2" indeterminate/>
      </v-sheet>
      <slot name="append"/>
    </template>
    <template v-slot:append-outer>
      <slot name="appendOuter"/>
    </template>
    <template v-slot:no-data>
      <div v-if="localLoading"></div>
      <v-list-item v-else>
        <v-list-item-content>
          <v-list-item-title>{{$tools.isEmpty(items) ? 'Введите текст...' : 'Не найдено'}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:item="{item}">
      <div class="v-list-item__title my-2 d-inline-block">
        <v-row no-gutters class="align-center">
          <v-col cols="auto"><slot name="itemIcon" v-bind:item="item"/></v-col>
          <v-col>
            <div>{{typeof item._name==='undefined' ? item.name : item._name}}</div>
            <slot name="itemInfo" v-bind:item="item"/>
          </v-col>
        </v-row>
      </div>
    </template>
    <template v-slot:selection="{item}">
      <chip :class="(typeof name==='undefined' ? '' : 'mt-1')" fullWidth>
        <v-row no-gutters class="align-center text-left">
          <v-col :class="typeof textOverflow !== 'undefined' ? 'text-overflow':''" >
            <slot name="selectedInfo" v-bind:item="item">{{typeof item._selected_name==='undefined' ? item.name : item._selected_name}}</slot>
          </v-col>
          <v-col v-if="readonly!==true" cols="auto" class="pl-2"><v-icon small @click.stop="localSelect=localSelect.filter((v)=>v.id!==item.id)">mdi-close-circle</v-icon></v-col>
        </v-row>
      </chip>
    </template>
  </v-autocomplete>
</template>

<script>
import Chip from "@/componentsV2/base/Chip.vue";

export default {
  components: {Chip},
  props: ['name','items','select','searchText','loading','backgroundColor','required','readonly', 'textOverflow'],
  data: () => ({
    localSelect:[],
    localSearchText:null,
    localLoading:false
  }),
  mounted() {
    this.localSelect = this.select;
  },
  watch:{
    select(){
      this.localSelect = this.select;
      this.localSearchText = null;
    },
    localSelect(){
      this.$emit('update:select', this.localSelect)
    },
    loading(){
      this.localLoading = this.loading;
    },
    localLoading(){
      this.$emit('update:loading', this.loading)
    },
    localSearchText(){
      if (this.localSearchText!==null)
      this.$tools.throttle(()=>{
        this.$emit('update:searchText', this.localSearchText)
      })
    },
  },
  methods: {
    getItemText(item) {
      return `${item.id} - ${item.name}`;
    }
  }
}
</script>
